import WasmController from "react-lib/frameworks/WasmController";
import { State as MainState } from "../../../../../HIS/MainHISInterface";
// apis
import Icd10List from "issara-sdk/apis/Icd10List_core";
import FormDataList from "issara-sdk/apis/FormDataList_apps_PTM";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import DiagnosisMedicalRecordDetail from "issara-sdk/apis/DiagnosisMedicalRecordDetail_apps_DPO";
import MeasurementList from "issara-sdk/apis/MeasurementList_apps_PTM";
import AdmissionList from "issara-sdk/apis/AdmissionList_apps_DPI";
import FormDataActionLogAllList from "issara-sdk/apis/FormDataActionLogAllList_apps_PTM";
import FormDataDetail from "issara-sdk/apis/FormDataDetail_apps_PTM";
import ConstanceView from "issara-sdk/apis/ConstanceView_core";
import PatientEMRList from "issara-sdk/apis/PatientEMRList_apps_DPO";
import DoctorCertificatesTypeList from "issara-sdk/apis/DoctorCertificatesTypeList_apps_DPO";
import AdmitOrderList from "issara-sdk/apis/AdmitOrderList_apps_ADM";
import DoctorOptimizedList from "issara-sdk/apis/DoctorOptimizedList_core";
// import PrenameList from "issara-sdk/apis/PrenameList_core";
import DivisionList from "issara-sdk/apis/DivisionList_core";

// Utils
import moment from "moment";
import getPdfMake from "react-lib/appcon/common/pdfMake";
import { beToAd, formatDate, formatDatetime, momentToStringBE } from "react-lib/utils/dateUtils";
// PDF TH
import FormDeathCertificate from "../pdfForm/FormDeathCertificate";
import { default as FormFiveDiseasesFoundCertificate } from "../pdfForm/FormFiveDiseasesFoundCertificate";
import { default as FormFiveDiseasesFreeCertificate } from "../pdfForm/FormFiveDiseasesFreeCertificate";
import { default as FormExamAbsenceCertificate } from "../pdfForm/FormExamAbsenceCertificate";
import { default as FormDrivingCertificate } from "../pdfForm/FormDrivingCertificate";
import { default as FormQuarantineCertificate } from "../pdfForm/FormQuarantineCertificate";
import { default as FormCovidCertificate } from "../pdfForm/FormCovidCertificate";
import { default as FormTravelCertificate } from "../pdfForm/FormTravelCertificate";
import { default as FormVisaExtensionCertificate } from "../pdfForm/FormVisaExtensionCertificate";
// PDF EN
import FormDeathCertificateEN from "../pdfForm/FormDeathCertificateEN";
import { default as FormFiveDiseasesFoundCertificateEN } from "../pdfForm/FormFiveDiseasesFoundCertificateEN";
import { default as FormFiveDiseasesFreeCertificateEN } from "../pdfForm/FormFiveDiseasesFreeCertificateEN";
import { default as FormExamAbsenceCertificateEN } from "../pdfForm/FormExamAbsenceCertificateEN";
import { default as FormDrivingCertificateEN } from "../pdfForm/FormDrivingCertificateEN";
import { default as FormQuarantineCertificateEN } from "../pdfForm/FormQuarantineCertificateEN";
import { default as FormCovidCertificateEN } from "../pdfForm/FormCovidCertficateEN";
import { default as FormTravelCertificateEN } from "../pdfForm/FormTravelCertificateEN";
import { default as FormVisaExtensionCertificateEN } from "../pdfForm/FormVisaExtensionCertificateEN";
import { createPDFMedicalCertificate } from "../pdfForm/FormDentistCertificate";

// Sequence
import { AddHeaderMedCert, AddHeaderMedCertEN } from "./AddHeaderSapienForm";
// Config
import CONFIG from "config/config";

export type State = {
  // Sequence
  ButtonLoadCheck?: any;
  LoadingStatus?: any;
  masterOptions?: any;
  errorMessage?: any;
  divisionOptions?: any;
  DoctorCertificateSequence?: {
    sequenceIndex?: any;

    // CardMedCertificateReport
    // selectReportType?: number;
    searchReportLoading?: boolean;
    isDoctor?: boolean;
    reportOption?: any;
    // languageOption?: any;
    selectedReport?: any;
    originalReportList?: any;
    reportList?: any;
    reportTypeList?: any[];
    formCodeList?: any[];
    doctorList?: any[];
    currentEncounter?: number;
    filterReport?: {
      startDate?: string;
      endDate?: string;
      doctorName?: any;
      reportLanguage?: string;
    };
    // CardDeathCertificate
    deathReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      icd10Options?: any;
      deathDate?: string;
      deathLocate?: string;
      deathLocateEn?: string;
      icd10TypeA?: any;
      amountA?: string;
      amountTypeA?: string;
      icd10TypeB?: any;
      amountB?: string;
      amountTypeB?: string;
      icd10TypeC?: any;
      amountC?: string;
      amountTypeC?: string;
      icd10TypeD?: any;
      amountD?: string;
      amountTypeD?: string;
      condition1Type?: any;
      amountCondition1?: string;
      amountTypeCondition1?: string;
      condition2Type?: any;
      amountCondition2?: string;
      amountTypeCondition2?: string;
      deathManner?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
    };
    // CardFiveDiseasesCertificate
    fiveReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      examDate?: string;
      isFoundDisease?: string;
      isFirstDisease?: boolean;
      isSecondDisease?: boolean;
      isThirdDisease?: boolean;
      isFourthDisease?: boolean;
      isFifthDisease?: boolean;
      otherNote?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardExamAbsenceCertificate
    examAbsenceReport?: {
      patientInfo?: any;
      encounterInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      chiefComplaint?: string;
      diagnosis?: string;
      comment?: string;
      examDate?: string;
      shouldOpdAbsence?: boolean;
      opdStartDate?: string;
      opdEndDate?: string;
      admitStartDate?: string;
      admitEndDate?: string;
      shouldIpdAbsence?: boolean;
      ipdStartDate?: string;
      ipdEndDate?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardDrivingCertificate
    drivingReport?: {
      patientInfo?: any;
      patientWeight?: any;
      patientHeight?: any;
      patientPressure?: any;
      patientPulse?: any;
      createdDate?: string;
      createdDateForm?: string;
      examDate?: string;
      hospitalName?: string;
      hospitalNameEn?: string;
      hospitalAddress?: string;
      isFirstOption?: string;
      explainFirst?: string;
      isSecondOption?: string;
      explainSecond?: string;
      isThirdOption?: string;
      explainThird?: string;
      isFourthOption?: string;
      explainFourth?: string;
      isFifthOption?: string;
      explainFifth?: string;
      summaryCheck?: string;
      explainSummary?: string;
      comment?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
    };
    // CardQuarantineCertificate
    quarantineReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      chiefComplaint?: string;
      detectDate?: string;
      comment?: string;
      shouldAbsence?: boolean;
      absenceDuration?: string;
      absenceStartDate?: string;
      absenceEndDate?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardCovidCertificate
    covidReport?: {
      patientInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      examDate?: string;
      examTime?: string;
      testedResult?: string;
      testedMethod?: string;
      swabDate?: string;
      getAdvised?: boolean;
      comment?: string;
      shouldAbsence?: boolean;
      absenceDuration?: string;
      absenceStartDate?: string;
      absenceEndDate?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardTravelCertificate
    travelReport?: {
      patientInfo?: any;
      encounterInfo?: any;
      createdDate?: string;
      createdDateForm?: string;
      examDate?: string;
      visitType?: string;
      flightDate?: string;
      diagnosis?: string;
      travelRecommend?: string;
      specialRequire?: any;
      comment?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardVisaExtensionCertificate
    visaExtReport?: {
      patientInfo?: any;
      encounterInfo?: any;
      doctorEncounter?: any;
      createdDate?: string;
      createdDateForm?: string;
      patientNationality?: string;
      patientPassport?: string;
      visitType?: string;
      chiefComplaint?: string;
      diagnosis?: string;
      isDrug?: boolean; // ให้ยา
      isPhysicalTherapy?: boolean; // กายภาพบำบัด
      isXray?: boolean; // Xray
      isProcedure?: boolean; // หัตถการ
      isPathology?: boolean; // พยาธิวิทยา
      isSurgery?: boolean; // ผ่าตัด
      isBandage?: boolean; // ทำแผล
      isOther?: boolean; // อื่น ๆ
      isProcedureNote?: string;
      isSurgeryNote?: string;
      isOtherNote?: string;
      treatmentDuration?: string;
      treatmentDoctor?: string;
      doctorName?: string;
      doctorNameEn?: string;
      doctorCertNumber?: string;
      reportLanguage?: string;
      reportType?: string;
      reportDuplicate?: string;
      printCount?: number;
      // Logic Value
      ageYear?: number;
      ageMonth?: number;
      ageDay?: number;
    };
    // CardDentistCertificate
    dentistReport?: {
      detail?: string;
      diagnosis?: string;
      doctor?: number | null;
      doctor_certificate_no?: string | null;
      pediatric_dentists?: number | null;
      is_pediatric_dentists?: boolean;
      language?: string;
      reportLanguage?: string;
      treatment?: string;
      reportType?: string;
      created?: string; // format yyyy(AD)-mm-dd [hh:mm:ss]
      print_datetime?: string; // format yyyy(AD)-mm-dd [hh:mm]
      hn?: string;
      patient_citizen_no?: string;
      division_name?: string;
      code?: string;
      report_name?: string;
      print_count?: number;
      // Addition (ไม่เอาไปใส่ใน data ตอนบันทึก)
      patient_name_th?: string;
      patient_name_en?: string;
      patient_age_th?: string;
      patient_age_en?: string;
      doctor_name_th?: string;
      doctor_name_en?: string;
      pediatric_dentists_name_th?: string;
      pediatric_dentists_name_en?: string;
      division_name_th?: string;
      division_name_en?: string;
    };
  };
};

export const StateInitial: State = {
  // Sequence
  DoctorCertificateSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
};

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "successMessage"
    | "masterOptions"
    | "django"
    | "selectedEncounter"
    | "selectedPatient"
    | "selectedEmr"
    | "vitalSignsInfo"
    | "loadingStatus"
  >
>;

type Handler = (controller: WasmController<State & Picked, Event, Data>, params?: any) => any;

export const DataInitial = {};

const disableHeaderForm = CONFIG.DISABLE_HEADER_MEDCERT; // true = disable, false = enable

// Current Date
// const currentDate = new Date().toISOString().split("T")[0];
const currentDate = moment().format("YYYY-MM-DD, HH:mm");
const christYear = moment(currentDate).format("YYYY");
const buddhistYear = (parseInt(christYear) + 543).toString();
const formattedDate = moment(currentDate).format("DD/MM/YYYY".replace("YYYY", buddhistYear));
const createdDate = moment(currentDate).format("DD/MM/YYYY, HH:mm".replace("YYYY", buddhistYear));
const createDateForm = moment(currentDate).format("DD/MM/YYYY, HH:mm");

// Age Calculate (CurrentDate - BirthDate) // birth and current must be formatted by using beToAd() before.
const getAge = (birthDate: string, currentDate: string) => {
  const diffDate = moment(currentDate).diff(birthDate);
  var yearDuration = moment.duration(diffDate).years();
  var monthDuration = moment.duration(diffDate).months();
  var dayDuration = moment.duration(diffDate).days();
  // [0] = Year, [1] = Month, [2] = Day
  return [yearDuration, monthDuration, dayDuration];
};

// Get Day, Month, Year from Date Format
const getSliceDate = (date: any) => {
  var getDay = date.slice(0, 2);
  var getMonth = date.slice(3, 5);
  var getMonthName = moment(getMonth, "MM").locale("th").format("MMMM").toString();
  var getYear = date.slice(6, 10);
  // [0] = Day, [1] = Month, [2] = MonthName, [3] = Year
  return [getDay, getMonth, getMonthName, getYear];
};

// Identification Number Format X-XXXX-XXXXX-XX-X
const formatIdentification = (ident: string) => {
  if (ident != null && ident.length > 12) {
    return [
      ident.slice(0, 1),
      ident.slice(1, 5),
      ident.slice(5, 10),
      ident.slice(10, 12),
      ident.slice(12, 13),
    ].join("-");
  }
  return null;
};

// Undefined Check
const undefinedChecker = (value: any) => {
  if (value == null) {
    return "";
  }

  return value;
};

const THAI_MONTHS = {
  มกราคม: "January",
  กุมภาพันธ์: "February",
  มีนาคม: "March",
  เมษายน: "April",
  พฤษภาคม: "May",
  มิถุนายน: "June",
  กรกฎาคม: "July",
  สิงหาคม: "August",
  กันยายน: "September",
  ตุลาคม: "October",
  พฤศจิกายน: "November",
  ธันวาคม: "December",
} as any;

export const formCodeMap = {
  CardDeathCertificate: "CardDeathCertificate",
  CardFiveDiseasesCertificate: "CardFiveDiseasesCertificate",
  CardExamAbsenceCertificate: "CardExamAbsenceCertificate",
  CardDrivingCertificate: "CardDrivingCertificate",
  CardQuarantineCertificate: "CardQuarantineCertificate",
  CardCovidCertificate: "CardCovidCertificate",
  CardTravelCertificate: "CardTravelCertificate",
  CardVisaExtensionCertificate: "CardVisaExtensionCertificate",
  CardDentistCertificate: "CardDentistCertificate",
  MedicalCertificateCUDent: "MedicalCertificateCUDent", // from cudent case
} as const;

const fieldsEncounterInfoRemove = [
  "doctor_order",
  "document",
  "edit_encounter",
  "hn_encounter",
  "patient_full_name",
  "status_name",
  "triage_level",
];

const reg = / \((.*)\)$/g;

const Masters = [["nationality", {}], ["doctorName"]] as const;

export const Start: Handler = async (controller, params) => {
  // console.log("Start State Doctor Certificate");

  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      master: Masters,
    },
  });

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  // get Report Type List
  const [getReportTypeResp, getReportTypeErr] = await DoctorCertificatesTypeList.list({
    apiToken: controller.apiToken,
  });

  let reportTypeList: any[] = [];
  let formCodeList: any[] = [];

  if (!getReportTypeErr) {
    // เอา "ใบรับรองแพทย์" ที่ cudent ใช้ออกจาก list
    const filteredType = getReportTypeResp.items?.filter(
      (item: any) => item.form_type_code !== "MedicalCertificateCUDent"
    );
    reportTypeList = filteredType?.map((item: any) => {
      return {
        key: item.form_type_code,
        value: item.form_type_code,
        text: item.name,
        language_list: item.language_list.map((lang: any) => ({
          key: lang.id,
          value: lang.id,
          text: lang.name,
        })),
      };
    });

    formCodeList = getReportTypeResp.items
      ?.filter((item: any) => item.form_type_code != null)
      .map((item: any) => {
        return item.form_type_code;
      });
  }

  const filterReport = await GetFormDataActionLogAllList(controller, {
    filter: { start_date: formattedDate, end_date: formattedDate },
  });

  // const filterReportTest = await GetFormDataActionLogAllList(controller, {
  //   filter: { start_date: formattedDate, end_date: formattedDate, form_type_code: formCodeListTest[0] }
  // })

  // issue #69664
  const filterCertificateReportList = filterReport.filter((item: any) =>
    formCodeList.includes(item.form_type_code)
  );

  // console.log("Doctor Cert Type List: ", getReportTypeResp);
  // console.log("Doctor Cert Form Code List from Cert Type: ", formCodeList);
  // console.log("Doctor Cert Filter Report (Specific): ", filterReportTest);

  let isDoctor = state.django?.user?.role_types?.includes("DOCTOR");

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      sequenceIndex: "Action",
      reportOption: "default",
      // languageOption: [],
      isDoctor: isDoctor,
      selectedReport: null,
      originalReportList: filterCertificateReportList,
      reportList: filterCertificateReportList,
      reportTypeList: reportTypeList || [],
      formCodeList: formCodeList || [],
      currentEncounter: state.selectedEncounter?.id,
      filterReport: {
        startDate: formattedDate,
        endDate: formattedDate,
        doctorName: "",
        reportLanguage: "ไทย",
      },
    },
  });

  console.log("Start State Props: ", state.DoctorCertificateSequence);
};

export const Action: Handler = async (controller, params) => {
  let state = controller.getState();
  let isDoctor = state.django?.user?.role_types?.includes("DOCTOR");

  // Set State isDoctor
  controller.setState({
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      isDoctor: isDoctor,
    },
  });

  // FETCH CERTIFICATE SEQUENCE
  if (params.action === "FETCH_CERTIFICATE") {
    if (!params.fetchType) {
      return;
    }

    switch (params.fetchType) {
      case formCodeMap.CardDeathCertificate:
        GetDeathCertificateData(controller, params);
        break;
      case formCodeMap.CardFiveDiseasesCertificate:
        GetFiveDiseasesCertificateData(controller, params);
        break;
      case formCodeMap.CardExamAbsenceCertificate:
        GetExamAbsenceCertificateData(controller, params);
        break;
      case formCodeMap.CardDrivingCertificate:
        GetDrivingCertificateData(controller, params);
        break;
      case formCodeMap.CardQuarantineCertificate:
        GetQuarantineCertificateData(controller, params);
        break;
      case formCodeMap.CardCovidCertificate:
        GetCovidCertificateData(controller, params);
        break;
      case formCodeMap.CardTravelCertificate:
        GetTravelCertificateData(controller, params);
        break;
      case formCodeMap.CardVisaExtensionCertificate:
        GetVisaExtensionCertificateData(controller, params);
        break;
      case formCodeMap.CardDentistCertificate:
      case formCodeMap.MedicalCertificateCUDent:
        GetDentistCertificateData(controller, params);
        break;
      default:
        console.warn("Doesn't Exist!!");
        break;
    }
  } else if (params.action === "SAVE_REPORT") {
    HandlePrintNSaveReport(controller, params);
  } else if (params.action === "GET_REPORT") {
    // console.log("GET_REPORT Params: ", params);
    if (!params.codeForm) {
      return;
    }

    const reportList = await GetFormDataActionLogAllList(controller, {});

    // issue #69664
    const filterCertificateReportList = reportList.filter((item: any) =>
      state.DoctorCertificateSequence?.formCodeList?.includes(item.form_type_code)
    );

    state = controller.getState();

    controller.setState({
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        originalReportList: filterCertificateReportList,
        reportList: filterCertificateReportList,
      },
    });

    // console.log("GET_REPORT Filter Report: ", state.DoctorCertificateSequence?.filterReport);

    // console.log("GET_REPORT Report List (After): ", state.DoctorCertificateSequence?.reportList);

    // Have Restart Params from GET_REPORT ?
    if (params.restart === true) {
      Start(controller, {
        message: "From GET_REPORT Sequence",
      });
    }
  } else if (params.action === "PREVIEW_REPORT") {
    HandlePrintNSaveReport(controller, { ...params, save: false, preview: true });
  } else if (params.action === "REPRINT_REPORT") {
    HandlePrintNSaveReport(controller, { ...params, reprint: true });
  } else if (params.action === "SELECT_DATA") {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
    });

    console.log("Select Report Type: ", state.DoctorCertificateSequence?.reportOption);
    console.log("SELECT_DATA params original reportType: ", params?.selectedReport);

    const [reportDetailResp] = await FormDataList.list({
      apiToken: controller.apiToken,
      params: {
        limit: 9999,
        encounter__patient: state.selectedEncounter?.patient,
      },
    });

    const reportSelected = reportDetailResp.items?.find(
      (item: any) => item.id === params?.selectedReport
    );
    // .map((item: any) => ({ ...item }));

    console.log("SELECT_DATA Raw Report: ", reportDetailResp);
    console.log("SELECT_DATA Report Map: ", reportSelected);
    console.log("SELECT_DATA Report Type: ", reportSelected.data?.reportType);

    const reportTypeMap: any = {
      CardDeathCertificate: "CardDeathCertificate",
      CardFiveDiseasesCertificate: "CardFiveDiseasesCertificate",
      CardExamAbsenceCertificate: "CardExamAbsenceCertificate",
      CardDrivingCertificate: "CardDrivingCertificate",
      CardQuarantineCertificate: "CardQuarantineCertificate",
      CardCovidCertificate: "CardCovidCertificate",
      CardTravelCertificate: "CardTravelCertificate",
      CardVisaExtensionCertificate: "CardVisaExtensionCertificate",
      CardDentistCertificate: "CardDentistCertificate",
      MedicalCertificateCUDent: "CardDentistCertificate", // from cudent case
    };

    // สำหรับ Form Med Cert เก่า
    const reportTypeMapLegacy: any = {
      ใบรับรองแพทย์: "CardDentistCertificate", // from cudent case
      "ใบรับรองแพทย์ (ทันตกรรม)": "CardDentistCertificate",
      ใบรับรองแพทย์สำหรับการเสียชีวิต: "CardDeathCertificate",
      "ใบรับรองแพทย์ 5 โรค": "CardFiveDiseasesCertificate",
      "ใบรับรองแพทย์สำหรับการตรวจ/ลางาน": "CardExamAbsenceCertificate",
      "ใบรับรองแพทย์สำหรับสมัคร/ต่อใบขับขี่": "CardDrivingCertificate",
      ใบรับรองการกักตัวหลังตรวจพบเชื้อโควิด: "CardQuarantineCertificate",
      ใบรับรองผลการตรวจโควิด: "CardCovidCertificate",
      ใบรับรองแพทย์สำหรับการเดินทาง: "CardTravelCertificate",
      "ใบรับรองแพทย์สำหรับ Visa Extension": "CardVisaExtensionCertificate",
    };

    const getReportType = (reportOption: string) => {
      if (!reportTypeMapLegacy[reportOption]) {
        return reportTypeMap[reportOption || ""];
      }
      return reportTypeMapLegacy[reportOption || ""];
    };

    const reportOption = state.DoctorCertificateSequence?.reportOption;
    const reportType = getReportType(reportSelected.data?.reportType || reportSelected.data?.type);

    if (reportType === reportOption) {
      controller.setState({
        loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      });

      return;
    }

    if (!reportType) {
      controller.setState({
        loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      });
      console.warn("Unsupported report type:", reportOption);

      return;
    }

    const reportKey = `${reportType.charAt(0).toLowerCase() + reportType.slice(1)}Report`;

    controller.setState({
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        reportOption: `${reportType}`,
        selectedReport: {
          ...reportSelected,
          logId: params.selectedReportId,
        },
        [reportKey]: {
          ...reportSelected.data,
        },
      },
    });
  } else if (params.action === "SEARCH_REPORT") {
    controller.setState({
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        // reportList: filteredList,
        searchReportLoading: true,
      },
    });
    const filter = state.DoctorCertificateSequence?.filterReport || {
      startDate: formattedDate,
      endDate: formattedDate,
      doctorName: "",
      reportLanguage: "",
    };

    const originalReportList = state.DoctorCertificateSequence?.originalReportList || [];

    const reportList =
      originalReportList > 0
        ? originalReportList
        : state.DoctorCertificateSequence?.reportList || [];

    // Filter Doctor Name to Doctor ID
    const doctorFilterName = (input: any) => {
      if (input !== "") {
        if (state.selectedEncounter?.doctor_name.includes(input)) {
          return state.selectedEncounter?.doctor;
        } else {
          return 0;
        }
      }
      return state.selectedEncounter?.doctor;
    };

    // Call API
    const filterReport = await GetFormDataActionLogAllList(controller, {
      filter: {
        doctor: doctorFilterName(filter?.doctorName),
        start_date: filter?.startDate,
        end_date: filter?.endDate,
        checkAllDoctor: params.checkAllDoctor,
      },
    });

    // issue #69664
    const filterCertificateReportList = filterReport.filter((item: any) =>
      state.DoctorCertificateSequence?.formCodeList?.includes(item.form_type_code)
    );

    // console.log("SEARCH_MedCert: ", formFilterResp);
    // console.log("SEARCH_MedCert Map: ", filterReport);

    // console.log("SEARCH After Filtered: ", filteredList);

    controller.setState({
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        // reportList: filteredList,
        reportList: filterCertificateReportList.sort((a: any, b: any) =>
          a.datetime > b.datetime ? -1 : 1
        ),
        searchReportLoading: false,
      },
    });
  } else if (params.action === "CLEAR_REPORT") {
    if (params.callReport) {
      // Update Report
      Action(controller, {
        action: "GET_REPORT",
        codeForm: params.callReport,
        restart: true,
      });
    }

    Start(controller, {
      message: "From CLEAR_REPORT Sequence",
    });
  }
};

/* ------------------------------------------------------ */
/*                         Handler                        */
/* ------------------------------------------------------ */
const GetDeathCertificateData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const commonInfo = await GetCommonInfoForMedCert(controller, params);

  // Death Cert APIs
  const [icd10Resp, icd10Err] = await Icd10List.list({
    apiToken: controller.apiToken,
    params: { limit: 999 },
  });

  const newIcd10Options = icd10Resp.items.map((item: { id: number; term: string }) => ({
    key: item.id,
    value: item.term,
    text: item.term,
  }));

  // Role condition at reportDuplicate if Doctor = true --> reportDuplicate = "ต้นฉบับ" else reportDuplicate = "สำเนา"
  if (state.DoctorCertificateSequence?.selectedReport) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        deathReport: {
          ...state.DoctorCertificateSequence?.selectedReport?.data,
        },
      },
    });

    return;
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      deathReport: {
        ...state.DoctorCertificateSequence?.deathReport,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        deathDate: formattedDate,
        // EN Ver use "Death Certificate", TH Ver use "ใบรับรองแพทย์สำหรับการเสียชีวิต"
        reportType: formCodeMap.CardDeathCertificate,
        reportDuplicate: "ต้นฉบับ",
        reportLanguage: "ไทย",
        icd10Options: newIcd10Options,
        // Patient Info
        patientInfo: state.selectedPatient,
        deathLocate: commonInfo?.hospitalNameTh || ` `,
        deathLocateEn: commonInfo?.hospitalNameEn || ` `,
        doctorName: commonInfo?.doctorNameTh,
        doctorNameEn: commonInfo?.doctorNameEn,
        doctorCertNumber: commonInfo?.doctorCertNumber,
        printCount: 1,
      },
    },
  });

  // Get Report
  Action(controller, {
    action: "GET_REPORT",
    codeForm: formCodeMap.CardDeathCertificate,
  });
};

const GetFiveDiseasesCertificateData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const commonInfo = await GetCommonInfoForMedCert(controller, params);

  // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
  const patientAgeFiveReport = getAge(
    beToAd(state.selectedPatient?.birthdate),
    // beToAd(formattedDate)
    beToAd(state.DoctorCertificateSequence?.fiveReport?.examDate)
  );

  if (state.DoctorCertificateSequence?.selectedReport) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        fiveReport: {
          ...state.DoctorCertificateSequence?.selectedReport?.data,
        },
      },
    });

    return;
  }
  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      fiveReport: {
        ...state.DoctorCertificateSequence?.fiveReport,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        examDate: formattedDate,
        isFirstDisease: false,
        isSecondDisease: false,
        isThirdDisease: false,
        isFourthDisease: false,
        isFifthDisease: false,
        otherNote: "",
        reportType: formCodeMap.CardFiveDiseasesCertificate,
        reportDuplicate: "ต้นฉบับ",
        reportLanguage: "ไทย",
        // Patient Info
        patientInfo: state.selectedPatient,
        doctorName: commonInfo?.doctorNameTh,
        doctorNameEn: commonInfo?.doctorNameEn,
        doctorCertNumber: commonInfo?.doctorCertNumber,
        isFoundDisease: "Free", //* default
        printCount: 1,
        // Logic Value
        ageYear: patientAgeFiveReport[0],
        ageMonth: patientAgeFiveReport[1],
        ageDay: patientAgeFiveReport[2],
      },
    },
  });

  // Get Report
  Action(controller, {
    action: "GET_REPORT",
    codeForm: formCodeMap.CardFiveDiseasesCertificate,
  });
};

const GetExamAbsenceCertificateData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const commonInfo = await GetCommonInfoForMedCert(controller, params);
  const emrInfo = await GetEmrInfoForMedCert(controller, params);
  const encounterInfo = await filterObject(state.selectedEncounter, fieldsEncounterInfoRemove);

  // Format Admit Date (XX/XX/XXX [XX:XX] -> XX/XX/XXXX)
  let originAdmitDate = state.selectedPatient?.admit_date;
  if (originAdmitDate === undefined || originAdmitDate === null || originAdmitDate === "") {
    originAdmitDate = "invalid date";
  } else {
    const preAdmitDate = originAdmitDate.slice(0, 10);
    const newAdmitDate = moment(preAdmitDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    const christYear = moment(newAdmitDate).format("YYYY");
    const buddhistYear = (parseInt(christYear) + 543).toString();
    originAdmitDate = moment(newAdmitDate).format("DD/MM/YYYY".replace("YYYY", buddhistYear));
  }

  // OPD ExamDate
  const opdExamDate = (date: any) => {
    return date.slice(0, 10);
  };

  // Chief Complaint Condition (get Chief Complaint from Admission Form)
  let patientChiefComplaint = null;
  if (state.selectedEncounter?.type === "OPD") {
    patientChiefComplaint = emrInfo?.opdChiefComplaint;
  } else if (state.selectedEncounter?.type === "IPD") {
    patientChiefComplaint = emrInfo?.ipdChiefComplaint;
  } else {
    patientChiefComplaint = "";
  }

  // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
  const patientAgeExamAbsenceReport = getAge(
    beToAd(state.selectedPatient?.birthdate),
    // beToAd(formattedDate)
    beToAd(state.DoctorCertificateSequence?.examAbsenceReport?.examDate)
  );

  if (state.DoctorCertificateSequence?.selectedReport) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        examAbsenceReport: {
          ...state.DoctorCertificateSequence?.selectedReport?.data,
        },
      },
    });

    return;
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      examAbsenceReport: {
        ...state.DoctorCertificateSequence?.examAbsenceReport,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        shouldOpdAbsence: false,
        shouldIpdAbsence: false,
        reportType: formCodeMap.CardExamAbsenceCertificate,
        reportDuplicate: "ต้นฉบับ",
        reportLanguage: "ไทย",
        // Patient Info
        patientInfo: state.selectedPatient,
        encounterInfo: encounterInfo,
        chiefComplaint: patientChiefComplaint,
        diagnosis: emrInfo?.diagnosis,
        // examDate: opdExamDate(state.selectedEncounter?.created),
        examDate: formattedDate,
        admitStartDate: emrInfo?.ipdAdmitDate || originAdmitDate,
        admitEndDate: formattedDate,
        doctorName: commonInfo?.doctorNameTh,
        doctorNameEn: commonInfo?.doctorNameEn,
        doctorCertNumber: commonInfo?.doctorCertNumber,
        printCount: 1,
        // Logic Value
        ageYear: patientAgeExamAbsenceReport[0],
        ageMonth: patientAgeExamAbsenceReport[1],
        ageDay: patientAgeExamAbsenceReport[2],
      },
    },
  });

  // Get Report
  Action(controller, {
    action: "GET_REPORT",
    codeForm: formCodeMap.CardExamAbsenceCertificate,
  });
};

const GetDrivingCertificateData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const commonInfo = await GetCommonInfoForMedCert(controller, params);

  // Measurement API
  const [measurementResp, measurementErr] = await MeasurementList.list({
    apiToken: controller.apiToken,
    params: {
      limit: 9999,
      encounter: state.selectedEncounter?.id,
      from_date: formattedDate,
      to_date: formattedDate,
      use_patient: true,
      invert_order: true,
    },
  });

  let patientVitalInfo = null;

  if (measurementResp.items[0] == null) {
    patientVitalInfo = {};
  } else {
    const patientVitalInfo = measurementResp.items[0].vital_signs.reduce((acc: any, item: any) => {
      let value = null;

      switch (item.vitalsign_type_code) {
        case "Weight":
        case "Height":
        case "SP":
        case "DP":
        case "PR":
        case "PULSE":
          value = item.formatted_string;
          break;
        default:
          value = null;
          break;
      }

      if (value !== null) {
        acc.push({ [`${item.vitalsign_type_code}`]: value });
      }

      return acc;
    }, []);

    const systolicItem = measurementResp.items[0].vital_signs.find(
      (item: any) => item.vitalsign_type_code === "SP"
    );
    const diastolicItem = measurementResp.items[0].vital_signs.find(
      (item: any) => item.vitalsign_type_code === "DP"
    );
    const systolicValue = systolicItem ? systolicItem.formatted_string : null;
    const diastolicValue = diastolicItem ? diastolicItem.formatted_string : null;

    if (systolicValue !== null && diastolicValue !== null) {
      patientVitalInfo.push({
        bloodPressure: `${systolicValue}/${diastolicValue}`,
      });
    }

    const getVitalInfo = (vitalNames: string[]) => {
      for (const name of vitalNames) {
        const vital = patientVitalInfo.find((item: any) => Object.hasOwn(item, name));

        if (vital) {
          return vital[name];
        }
      }

      return null;
    };

    var patientWeight = getVitalInfo(["Weight"]);
    var patientHeight = getVitalInfo(["Height"]);
    var patientPressure = getVitalInfo(["bloodPressure"]);
    var patientPulse = getVitalInfo(["PULSE", "PR"]);
  }

  if (state.DoctorCertificateSequence?.selectedReport) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        drivingReport: {
          ...state.DoctorCertificateSequence?.selectedReport?.data,
        },
      },
    });

    return;
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      drivingReport: {
        ...state.DoctorCertificateSequence?.drivingReport,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        reportType: formCodeMap.CardDrivingCertificate,
        reportDuplicate: "ต้นฉบับ",
        reportLanguage: "ไทย",
        // Patient Info
        patientInfo: state.selectedPatient,
        patientWeight: patientWeight,
        patientHeight: patientHeight,
        patientPressure: patientPressure,
        patientPulse: patientPulse,
        examDate: formattedDate,
        hospitalName: commonInfo?.hospitalNameTh,
        hospitalNameEn: commonInfo?.hospitalNameEn,
        hospitalAddress: commonInfo?.hospitalAddress,
        doctorName: commonInfo?.doctorNameTh,
        doctorNameEn: commonInfo?.doctorNameEn,
        doctorCertNumber: commonInfo?.doctorCertNumber,
        printCount: 1,
      },
    },
  });

  // Get Report
  Action(controller, {
    action: "GET_REPORT",
    codeForm: formCodeMap.CardDrivingCertificate,
  });
};

const GetQuarantineCertificateData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const commonInfo = await GetCommonInfoForMedCert(controller, params);
  const emrInfo = await GetEmrInfoForMedCert(controller, params);

  // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
  const patientAgeQuarantineReport = getAge(
    beToAd(state.selectedPatient?.birthdate),
    // beToAd(formattedDate)
    beToAd(state.DoctorCertificateSequence?.quarantineReport?.detectDate)
  );

  if (state.DoctorCertificateSequence?.selectedReport) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        quarantineReport: {
          ...state.DoctorCertificateSequence?.selectedReport?.data,
        },
      },
    });

    return;
  }
  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      quarantineReport: {
        ...state.DoctorCertificateSequence?.quarantineReport,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        detectDate: formattedDate,
        shouldAbsence: false,
        reportType: formCodeMap.CardQuarantineCertificate,
        reportDuplicate: "ต้นฉบับ",
        reportLanguage: "ไทย",
        // Patient Info
        patientInfo: state.selectedPatient,
        chiefComplaint: emrInfo?.opdChiefComplaint,
        doctorName: commonInfo?.doctorNameTh,
        doctorNameEn: commonInfo?.doctorNameEn,
        doctorCertNumber: commonInfo?.doctorCertNumber,
        printCount: 1,
        // Logic Value
        ageYear: patientAgeQuarantineReport[0],
        ageMonth: patientAgeQuarantineReport[1],
        ageDay: patientAgeQuarantineReport[2],
      },
    },
  });

  // Get Report
  Action(controller, {
    action: "GET_REPORT",
    codeForm: formCodeMap.CardQuarantineCertificate,
  });
};

const GetCovidCertificateData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const commonInfo = await GetCommonInfoForMedCert(controller, params);

  // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
  const patientAgeCovidReport = getAge(
    beToAd(state.selectedPatient?.birthdate),
    // beToAd(formattedDate)
    beToAd(state.DoctorCertificateSequence?.covidReport?.examDate)
  );

  if (state.DoctorCertificateSequence?.selectedReport) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        covidReport: {
          ...state.DoctorCertificateSequence?.selectedReport?.data,
        },
      },
    });

    return;
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      covidReport: {
        ...state.DoctorCertificateSequence?.covidReport,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        examDate: formattedDate,
        getAdvised: false,
        shouldAbsence: false,
        reportType: formCodeMap.CardCovidCertificate,
        reportDuplicate: "ต้นฉบับ",
        reportLanguage: "ไทย",
        // Patient Info
        patientInfo: state.selectedPatient,
        doctorName: commonInfo?.doctorNameTh,
        doctorNameEn: commonInfo?.doctorNameEn,
        doctorCertNumber: commonInfo?.doctorCertNumber,
        printCount: 1,
        // Logic value
        ageYear: patientAgeCovidReport[0],
        ageMonth: patientAgeCovidReport[1],
        ageDay: patientAgeCovidReport[2],
      },
    },
  });

  // Get Report
  Action(controller, {
    action: "GET_REPORT",
    codeForm: formCodeMap.CardCovidCertificate,
  });
};

const GetTravelCertificateData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const commonInfo = await GetCommonInfoForMedCert(controller, params);
  const emrInfo = await GetEmrInfoForMedCert(controller, params);
  const encounterInfo = await filterObject(state.selectedEncounter, fieldsEncounterInfoRemove);

  // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
  const patientAgeTravelReport = getAge(
    beToAd(state.selectedPatient?.birthdate),
    // beToAd(formattedDate)
    beToAd(state.DoctorCertificateSequence?.travelReport?.examDate)
  );

  if (state.DoctorCertificateSequence?.selectedReport) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        travelReport: {
          ...state.DoctorCertificateSequence?.selectedReport?.data,
        },
      },
    });

    return;
  }
  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      travelReport: {
        ...state.DoctorCertificateSequence?.travelReport,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        examDate: formattedDate,
        reportType: formCodeMap.CardTravelCertificate,
        reportDuplicate: "ต้นฉบับ",
        reportLanguage: "ไทย",
        // Patient Info
        patientInfo: state.selectedPatient,
        encounterInfo: encounterInfo,
        diagnosis: emrInfo?.diagnosis,
        doctorName: commonInfo?.doctorNameTh,
        doctorNameEn: commonInfo?.doctorNameEn,
        doctorCertNumber: commonInfo?.doctorCertNumber,
        printCount: 1,
        // Logic Value
        ageYear: patientAgeTravelReport[0],
        ageMonth: patientAgeTravelReport[1],
        ageDay: patientAgeTravelReport[2],
      },
    },
  });

  // Get Report
  Action(controller, {
    action: "GET_REPORT",
    codeForm: formCodeMap.CardTravelCertificate,
  });
};

const GetVisaExtensionCertificateData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const commonInfo = await GetCommonInfoForMedCert(controller, params);
  const emrInfo = await GetEmrInfoForMedCert(controller, params);
  const encounterInfo = await filterObject(state.selectedEncounter, fieldsEncounterInfoRemove);

  // Chief Complaint Condition (get Chief Complaint from Admission Form)
  let patientChiefComplaint = null;
  if (state.selectedEncounter?.type === "OPD") {
    patientChiefComplaint = emrInfo?.opdChiefComplaint;
  } else if (state.selectedEncounter?.type === "IPD") {
    patientChiefComplaint = emrInfo?.ipdChiefComplaint;
  } else {
    patientChiefComplaint = "";
  }

  // Age Calculation (Compare with formattedDate) [0] -> ageYear, [1] -> ageMonth, [2] -> ageDay
  const patientAgeVisaExtReport = getAge(
    beToAd(state.selectedPatient?.birthdate),
    // beToAd(formattedDate)
    beToAd(formattedDate)
  );

  if (state.DoctorCertificateSequence?.selectedReport) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        visaExtReport: {
          ...state.DoctorCertificateSequence?.selectedReport?.data,
        },
      },
    });

    return;
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      visaExtReport: {
        ...state.DoctorCertificateSequence?.visaExtReport,
        createdDate: createdDate,
        createdDateForm: createDateForm,
        isDrug: false,
        isPhysicalTherapy: false,
        isXray: false,
        isProcedure: false,
        isPathology: false,
        isSurgery: false,
        isBandage: false,
        isOther: false,
        isProcedureNote: "",
        isSurgeryNote: "",
        isOtherNote: "",
        treatmentDuration: "",
        reportType: formCodeMap.CardVisaExtensionCertificate,
        reportDuplicate: "ต้นฉบับ",
        reportLanguage: "ไทย",
        // Patient Info
        patientInfo: state.selectedPatient,
        encounterInfo: encounterInfo,
        doctorEncounter: state.selectedEncounter?.doctor || null,
        chiefComplaint: patientChiefComplaint,
        diagnosis: emrInfo?.diagnosis,
        patientNationality: state.selectedPatient?.nationality,
        patientPassport: state.selectedPatient?.citizen_passport,
        doctorName: commonInfo?.doctorNameTh,
        doctorNameEn: commonInfo?.doctorNameEn,
        doctorCertNumber: commonInfo?.doctorCertNumber,
        printCount: 1,
        // Logic Value
        ageYear: patientAgeVisaExtReport[0],
        ageMonth: patientAgeVisaExtReport[1],
        ageDay: patientAgeVisaExtReport[2],
      },
    },
  });

  // Get Report
  Action(controller, {
    action: "GET_REPORT",
    codeForm: formCodeMap.CardVisaExtensionCertificate,
  });
};

const GetDentistCertificateData: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const momentDate = moment();
  const date = momentDate.format("YYYY-MM-DD");
  const time = momentDate.format("HH:mm");
  const fullTime = momentDate.format("HH:mm:ss");
  const commonInfo = await GetCommonInfoForMedCert(controller, params);
  const emrInfo = await GetEmrInfoForMedCert(controller, params);
  const doctorList = await GetDoctorList(controller, params);
  const full_age = state.selectedPatient?.full_age || "";
  const [year, month, day] = full_age.match(/\d+ ?(?= )/g) || [];
  const divisionList = await GetDivisionList(controller, params);
  const mappedDivision = divisionList.map(({ id, name, name_en }: any) => ({ id, name, name_en }));

  // console.log("Yeti CUDent Med Cert CommonInfo: ", commonInfo);

  // console.log("Yeti selectedPatient and selectedEncounter: ", {patient: state.selectedPatient, encounter: state.selectedEncounter});
  // console.log("Yeti Selected Report: ", state.DoctorCertificateSequence?.selectedReport);

  if (state.DoctorCertificateSequence?.selectedReport) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      DoctorCertificateSequence: {
        ...state.DoctorCertificateSequence,
        doctorList: doctorList,
        dentistReport: {
          ...state.DoctorCertificateSequence?.selectedReport?.data,
          created: `${date} [${fullTime}]`,
          print_datetime: `${date} [${time}]`,
          code: state.DoctorCertificateSequence?.selectedReport?.id || "-",
          reportType:
            state.DoctorCertificateSequence?.selectedReport?.data?.type ||
            state.DoctorCertificateSequence?.selectedReport?.data?.reportType ||
            formCodeMap.CardDentistCertificate,
          reportLanguage:
            (state.DoctorCertificateSequence?.selectedReport?.data?.language === "TH"
              ? "ไทย"
              : state.DoctorCertificateSequence?.selectedReport?.data?.language) ||
            (state.DoctorCertificateSequence?.selectedReport?.data?.reportLanguage === "TH"
              ? "ไทย"
              : state.DoctorCertificateSequence?.selectedReport?.data?.reportLanguage),
        },
      },
    });

    return;
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    DoctorCertificateSequence: {
      ...state.DoctorCertificateSequence,
      doctorList: doctorList,
      dentistReport: {
        ...state.DoctorCertificateSequence?.dentistReport,
        detail: "",
        diagnosis: emrInfo?.diagnosis,
        doctor: state.selectedEncounter?.doctor || null,
        doctor_certificate_no: commonInfo?.doctorCertNumber,
        pediatric_dentists: state.selectedEncounter?.student || null,
        is_pediatric_dentists: state.selectedEncounter?.student ? true : false,
        reportLanguage: "ไทย",
        treatment: "",
        reportType: formCodeMap.CardDentistCertificate,
        created: `${date} [${fullTime}]`,
        print_datetime: `${date} [${time}]`,
        hn: state.selectedPatient?.hn,
        patient_citizen_no:
          state.selectedPatient?.citizen_no || state.selectedPatient?.citizen_passport || "-",
        division_name: state.selectedEncounter?.division_name,
        code: "-",
        report_name: "ใบรับรองแพทย์",
        print_count: 1,
        // for save in form
        patient_name_th: state.selectedPatient?.full_name_th,
        patient_name_en: state.selectedPatient?.full_name_en,
        patient_age_th: `${year} ปี ${month} เดือน ${day} วัน`,
        patient_age_en: `${year} years ${month} months ${day} days`,
        doctor_name_th: commonInfo?.doctorNameTh,
        doctor_name_en: commonInfo?.doctorNameEn,
        pediatric_dentists_name_th: doctorList?.find(
          (item: any) => item.value === state.selectedEncounter?.student
        )?.name_th,
        pediatric_dentists_name_en: doctorList?.find(
          (item: any) => item.value === state.selectedEncounter?.student
        )?.name_en,
        division_name_th: [
          { id: null, name: null, name_en: null },
          ...mappedDivision,
        ].find((item: any) => state.selectedEncounter?.division === item.id)?.name,
        division_name_en: [
          { id: null, name: null, name_en: null },
          ...mappedDivision,
        ].find((item: any) => state.selectedEncounter?.division === item.id)?.name_en,
      },
    },
  });

  // Get Report
  Action(controller, {
    action: "GET_REPORT",
    codeForm: formCodeMap.CardDentistCertificate,
  });
};

const HandlePrintNSaveReport: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: true },
  });

  const reportFormMap: any = {
    CardDeathCertificate: {
      formCode: "CardDeathCertificate",
      formName: "ใบรับรองแพทย์สำหรับการเสียชีวิต",
      pdfGenTh: FormDeathCertificate,
      pdfGenEn: FormDeathCertificateEN,
      data: state.DoctorCertificateSequence?.deathReport,
      formatter: formatDeathReport,
    },
    CardFiveDiseasesCertificate: {
      formCode: "CardFiveDiseasesCertificate",
      formName: "ใบรับรองแพทย์ 5 โรค",
      pdfGenTh: {
        normal: FormFiveDiseasesFoundCertificate,
        free: FormFiveDiseasesFreeCertificate,
      },
      pdfGenEn: {
        normal: FormFiveDiseasesFoundCertificateEN,
        free: FormFiveDiseasesFreeCertificateEN,
      },
      data: state.DoctorCertificateSequence?.fiveReport,
      formatter: formatFiveDiseasesReport,
    },
    CardExamAbsenceCertificate: {
      formCode: "CardExamAbsenceCertificate",
      formName: "ใบรับรองแพทย์สำหรับการตรวจ/ลางาน",
      pdfGenTh: FormExamAbsenceCertificate,
      pdfGenEn: FormExamAbsenceCertificateEN,
      data: state.DoctorCertificateSequence?.examAbsenceReport,
      formatter: formatExamAbsenceReport,
    },
    CardDrivingCertificate: {
      formCode: "CardDrivingCertificate",
      formName: "ใบรับรองแพทย์สำหรับสมัคร/ต่อใบขับขี่",
      pdfGenTh: FormDrivingCertificate,
      pdfGenEn: FormDrivingCertificateEN,
      data: state.DoctorCertificateSequence?.drivingReport,
      formatter: formatDrivingReport,
    },
    CardQuarantineCertificate: {
      formCode: "CardQuarantineCertificate",
      formName: "ใบรับรองการกักตัวหลังตรวจพบเชื้อโควิด",
      pdfGenTh: FormQuarantineCertificate,
      pdfGenEn: FormQuarantineCertificateEN,
      data: state.DoctorCertificateSequence?.quarantineReport,
      formatter: formatQuarantineReport,
    },
    CardCovidCertificate: {
      formCode: "CardCovidCertificate",
      formName: "ใบรับรองผลการตรวจโควิด",
      pdfGenTh: FormCovidCertificate,
      pdfGenEn: FormCovidCertificateEN,
      data: state.DoctorCertificateSequence?.covidReport,
      formatter: formatCovidReport,
    },
    CardTravelCertificate: {
      formCode: "CardTravelCertificate",
      formName: "ใบรับรองแพทย์สำหรับการเดินทาง",
      pdfGenTh: FormTravelCertificate,
      pdfGenEn: FormTravelCertificateEN,
      data: state.DoctorCertificateSequence?.travelReport,
      formatter: formatTravelReport,
    },
    CardVisaExtensionCertificate: {
      formCode: "CardVisaExtensionCertificate",
      formName: "ใบรับรองแพทย์สำหรับ Visa Extension",
      pdfGenTh: FormVisaExtensionCertificate,
      pdfGenEn: FormVisaExtensionCertificateEN,
      data: state.DoctorCertificateSequence?.visaExtReport,
      formatter: formatVisaExtReport,
    },
    CardDentistCertificate: {
      formCode: "CardDentistCertificate",
      formName: "ใบรับรองแพทย์ (ทันตกรรม)",
      pdfGenTh: createPDFMedicalCertificate,
      pdfGenEn: createPDFMedicalCertificate,
      data: state.DoctorCertificateSequence?.dentistReport,
      formatter: formatDentistReport,
    },
  };

  const selectedReportId = state.DoctorCertificateSequence?.selectedReport?.id;
  const selectedData = state.DoctorCertificateSequence?.selectedReport?.data;
  const reportOption = state.DoctorCertificateSequence?.reportOption || "";
  const reportForm = reportFormMap[reportOption];

  if (!reportForm) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
      errorMessage: {
        ...state.errorMessage,
        [params.sequence]: "กรุณาเลือกใบรับรองแพทย์ที่ต้องการ Preview",
      },
    });

    return;
  }

  const { isFreeDisease, error, ...formattedData } = await reportForm.formatter(reportForm.data, {
    nationality: state.masterOptions?.nationality,
    apiToken: controller.apiToken,
  });

  // console.log("Yeti Report Form: ", reportForm);
  // console.log("Yeti Prev Data: ", {select: selectedData, format: formattedData})

  // * ถ้าไม่ส่ง save = false จะทำการบันทึก Form data
  if (params.save !== false) {
    if (error) {
      controller.setState({
        loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
        errorMessage: { ...state.errorMessage, [params.sequence]: error },
      });

      return;
    }

    // const printCount = formattedData.printCount || 0;
    let formData = {};

    if (params.reprint) {
      const printCount = (selectedData?.print_count || selectedData?.printCount) || 0;
      formData = {
        action: "SAVE",
        data: {
          ...selectedData,
          reportLanguage: formattedData?.reportLanguage,
          ...(params.reprint && { printCount: printCount + 1 }),
        },
        encounter: state.DoctorCertificateSequence?.selectedReport?.encounter,
        doctor: state.DoctorCertificateSequence?.selectedReport?.doctor,
        form_code: reportForm.formCode,
        form_name: reportForm.formName,
        form_version: "1.0",
      };
    } else {
      formData = {
        action: "SAVE",
        data: {
          ...formattedData,
          printCount: 1, //* First Save or Edit will set printCount is 1
        },
        encounter: state.selectedEncounter?.id,
        doctor: state.selectedEncounter?.doctor,
        form_code: reportForm.formCode,
        form_name: reportForm.formName,
        form_version: "1.0",
      };
    }

    // Edit and Save old Encount Case
    // if (state.DoctorCertificateSequence?.selectedReport?.encounter === state.selectedEncounter?.id) {
    //   formData = {
    //     action: "SAVE",
    //     data: {
    //       ...formattedData,
    //       printCount: 1, //* First Save or Edit will set printCount is 1
    //     },
    //     encounter: state.selectedEncounter?.id,
    //     doctor: state.selectedEncounter?.doctor,
    //     form_code: reportForm.formCode,
    //     form_name: reportForm.formName,
    //     form_version: "1.0",
    //   };
    // } else {
    //   formData = {
    //     action: "SAVE",
    //     data: {
    //       ...formattedData,
    //       printCount: 1, //* First Save or Edit will set printCount is 1
    //     },
    //     encounter: state.DoctorCertificateSequence?.selectedReport?.encounter,
    //     doctor: state.DoctorCertificateSequence?.selectedReport?.doctor,
    //     form_code: reportForm.formCode,
    //     form_name: reportForm.formName,
    //     form_version: "1.0",
    //   };
    // }

    const response = selectedReportId
      ? await FormDataDetail.update({
          pk: selectedReportId,
          data: formData,
          extra: { division: controller.data.division },
          apiToken: controller.apiToken,
        })
      : await FormDataList.create({
          apiToken: controller.apiToken,
          data: formData,
          extra: { division: controller.data.division },
        });

    if (response[1]) {
      controller.setState({
        loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
        errorMessage: { ...state.errorMessage, [params.sequence]: response[1] },
      });

      return;
    }

    if (reportForm.formCode === "CardDentistCertificate") {
      if (response && response[0]?.id) {
        reportForm.data.code = response[0]?.id;
      } else {
        return;
      }
    }
  }

  const language = reportForm.data?.reportLanguage || "ไทย";

  let docDef: any = { content: [] };

  if (reportForm.formCode === "CardDentistCertificate") {
    const data = setPDFMedCertificateData(state.selectedEncounter, reportForm.data);
    await createPDFMedicalCertificate(data);

    controller.setState({
      loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
    });

    Action(controller, {
      action: "CLEAR_REPORT",
      callReport: reportForm.formCode,
    });

    return;
  }

  if (["ไทย", "EN"].includes(language)) {
    const pdfGen = language === "ไทย" ? reportForm.pdfGenTh : reportForm.pdfGenEn;
    const pdfGenerator = isFreeDisease ? pdfGen.free : pdfGen.normal || pdfGen;

    if (params.reprint) {
      docDef = await pdfGenerator({
        items: { ...selectedData, dayDuration: selectedData.dayDuration || " " },
      });
    } else {
      docDef = await pdfGenerator({
        items: { ...formattedData, dayDuration: formattedData.dayDuration || " " },
      });
    }
    const headerAdder = language === "ไทย" ? AddHeaderMedCert : AddHeaderMedCertEN;
    docDef = await headerAdder(docDef, reportForm.data?.createdDateForm, disableHeaderForm);
  } else {
    console.error("Not Found Language of Form");
  }

  const pdfMake = (await getPdfMake(true)).createPdf(docDef);

  if (params.preview) {
    pdfMake.open();
  } else {
    pdfMake.print();
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.sequence]: false },
  });

  Action(controller, {
    action: "CLEAR_REPORT",
    callReport: reportForm.formCode,
  });
};

/* ------------------------------------------------------ */
/*                          APIS                          */
/* ------------------------------------------------------ */
const GetFormDataActionLogAllList: Handler = async (controller, params) => {
  const state = controller.getState();

  const [result] = await FormDataActionLogAllList.list({
    apiToken: controller.apiToken,
    params: {
      limit: 9999,
      patient: state.selectedEncounter?.patient,
      ...(!params?.filter?.checkAllDoctor && {
        doctor: state.selectedEncounter?.doctor,
        ...(state.DoctorCertificateSequence?.filterReport?.startDate && {
          start_date: state.DoctorCertificateSequence?.filterReport?.startDate,
        }),
        ...(state.DoctorCertificateSequence?.filterReport?.endDate && {
          end_date: state.DoctorCertificateSequence?.filterReport?.endDate,
        }),
        ...params.filter,
      }),
    },
  });

  const items: any[] = result?.items || [];

  return [...new Map(items.map((item) => [item.statable, item])).values()];
};

const GetCommonInfoForMedCert: Handler = async (controller, params) => {
  const state = controller.getState();

  // Hospital Name
  const [hospitalNameThResp, hospitalNameThErr] = await ConstanceView.get({
    apiToken: controller.apiToken,
    params: {
      list: "core_HOSPITAL_NAME",
    },
    extra: {
      division: controller.data.division,
    },
  });

  const [hospitalNameEnResp, hospitalNameEnErr] = await ConstanceView.get({
    apiToken: controller.apiToken,
    params: {
      list: "core_HOSPITAL_NAME_EN",
    },
    extra: {
      division: controller.data.division,
    },
  });

  // Hospital Address
  const [hospitalAddressResp, hospitalAddressErr] = await ConstanceView.get({
    apiToken: controller.apiToken,
    params: {
      list: "core_HOSPITAL_ADDRESS",
    },
    extra: {
      division: controller.data.division,
    },
  });

  // Doctor Info (based on encounter)
  const [doctorSpecResp, doctorSpecErr] = await DoctorDetail.retrieve({
    apiToken: controller.apiToken,
    pk: state.selectedEncounter?.doctor,
    extra: {
      division: controller.data.division,
    }
  });

  // // Get Prename (For Doctor En)
  // const [prenameResp, prenameErr] = await PrenameList.list({
  //   apiToken: controller.apiToken,
  //   params: {
  //     language: "EN",
  //     disable_pagination: true,
  //   },
  //   extra: {
  //     division: controller.data.division,
  //   }
  // });

  const doctorInfo = doctorSpecResp ?? null;
  // const prenameList = prenameResp?.items !== 0 ? prenameResp?.items : [];
  // const getPrename = prenameList?.find((item: any) => item.id === doctorInfo?.pre_name_en)?.name || "";

  // console.log("Yeti DoctorInfo Check: ", {encounter: state.selectedEncounter, doctor: doctorInfo});

  const doctorName = doctorInfo ? doctorInfo.full_name : ` `;
  const doctorNameEn = doctorInfo ? `${doctorInfo.first_name_en} ${doctorInfo.last_name_en}` : ` `; // `${getPrename}${doctorInfo.first_name_en} ${doctorInfo.last_name_en}`
  const doctorCertNumber = doctorInfo ? doctorInfo.certificate_no : ` `;

  return {
    hospitalNameTh: hospitalNameThResp ? hospitalNameThResp?.result?.core_HOSPITAL_NAME : ` `,
    hospitalNameEn: hospitalNameEnResp ? hospitalNameEnResp?.result?.core_HOSPITAL_NAME_EN : ` `,
    hospitalAddress: hospitalAddressResp ? hospitalAddressResp?.result?.core_HOSPITAL_ADDRESS : ` `,
    doctorNameTh: doctorName,
    doctorNameEn: doctorNameEn,
    doctorCertNumber: doctorCertNumber,
  };
};

const GetEmrInfoForMedCert: Handler = async (controller, params) => {
  const state = controller.getState();

  // Chief Complaint (OPD)
  const [medicalRecordResp, medicalRecordErr] = await PatientEMRList.get({
    apiToken: controller.apiToken,
    emr: state.selectedEmr?.id,
    params: {
      auto_checkin: false,
    },
    extra: {
      division: controller.data.division,
    },
  });

  // Addmission Form (IPD)
  const [admisFormResp, admisFormErr, admisFormNet] = await AdmissionList.retrieve({
    apiToken: controller.apiToken,
    pk: state.selectedEmr?.id,
  });

  // Admit Order
  const [admitOrderResp, admitOrderErr] = await AdmitOrderList.list({
    apiToken: controller.apiToken,
    params: {
      encounter: state.selectedEncounter?.id,
      active: true,
    },
  });

  const formatStartAdmitDate = admitOrderResp
    ? formatDatetime(admitOrderResp.items[0]?.start_admited).split(" ")[0]
    : null;

  // Diagnosis
  const [diagExAbResp, diagExAbErr, diagExAbNet] = await DiagnosisMedicalRecordDetail.retrieve({
    apiToken: controller.apiToken,
    pk: state.selectedEmr?.id,
    params: { limit: 999 },
  });

  const formatDiagnosis =
    (diagExAbResp.principal_diagnosis?.[0]?.icd_code || "") +
      " " +
      (diagExAbResp.principal_diagnosis?.[0]?.icd_term || "") +
      (diagExAbResp.principal_diagnosis?.[0]?.medical_description || "") +
      (diagExAbResp.secondary_diagnosis?.[0]?.icd_code &&
      diagExAbResp.secondary_diagnosis?.[0]?.icd_term &&
      diagExAbResp.secondary_diagnosis?.[0]?.medical_description
        ? "," +
          (diagExAbResp.secondary_diagnosis?.[0]?.icd_code || "") +
          " " +
          (diagExAbResp.secondary_diagnosis?.[0]?.icd_term || "") +
          (diagExAbResp.secondary_diagnosis?.[0]?.medical_description || "")
        : "") || "";

  return {
    opdChiefComplaint: medicalRecordResp?.patient_illness?.chief_complaint || ``,
    ipdChiefComplaint: admisFormResp.admission_form?.chief_complaint || ``,
    ipdAdmitDate: formatStartAdmitDate,
    diagnosis: formatDiagnosis,
  };
};

const GetDoctorList: Handler = async (controller, params) => {
  const state = controller.getState();

  const [doctorListResp, doctorListErr] = await DoctorOptimizedList.list({
    apiToken: controller.apiToken,
    params: {
      disable_pagination: true,
    },
  });

  if (doctorListErr) {
    return [];
  } else {
    return mapDoctorOptions(doctorListResp?.items);
  }
};

const GetDivisionList: Handler = async (controller, params) => {
  const state = controller.getState();

  const [divisionResp, divisionErr] = await DivisionList.list({
    apiToken: controller.apiToken,
    params: {
      limit: 1000
    },
  });

  if (divisionErr) {
    return [];
  }

  return divisionResp?.items ?? [];
};

/* ------------------------------------------------------ */
/*                         Formate                        */
/* ------------------------------------------------------ */
const formatDeathReport = (data: any) => {
  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatFiveDiseasesReport = (data: any) => {
  const count = countDisease(data);
  const isFreeDisease = count.length === 0 && data?.isFoundDisease === "Free";

  return {
    ...data,
    count,
    isFreeDisease,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatExamAbsenceReport = (data: any) => {
  if (data.opdEndDate && !data.opdStartDate) {
    data.opdStartDate = formatDate(moment());
  }

  const visitTypeInfo = visitTypeCheck(data);
  const error =
    data.opdStartDate && !data.opdEndDate
      ? {
          ระยะเวลาที่ควรพักงาน: ["กรุณาระบุวันสิ้นสุด"],
        }
      : null;

  return {
    ...data,
    dayDuration: visitTypeInfo[3],
    patientExamDate: visitTypeInfo[4],
    patientVisitType: visitTypeInfo[0],
    patientAbsenceStart: visitTypeInfo[1],
    patientAbsenceEnd: visitTypeInfo[2],
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
    error,
  };
};

const formatDrivingReport = (data: any) => {
  const patientAddress = data?.patientInfo?.present_address || {};
  const examDate = data?.examDate ? getSliceDate(data.examDate) : ["", "", "", ""];

  return {
    ...data,
    checkAddressNo: undefinedChecker(patientAddress.no),
    checkAddressName: undefinedChecker(patientAddress.name),
    checkAddressTown: undefinedChecker(patientAddress.town),
    checkAddressRoad: undefinedChecker(patientAddress.road),
    checkAddressCity: undefinedChecker(patientAddress.city_label),
    checkAddressDistrict: undefinedChecker(patientAddress.district_label),
    checkAddressProvince: undefinedChecker(patientAddress.province_label),
    checkAddressCityEn: undefinedChecker(patientAddress.city_en_label),
    checkAddressDistrictEn: undefinedChecker(patientAddress.district_en_label),
    checkAddressProvinceEn: undefinedChecker(patientAddress.province_en_label),
    formatIdentification: formatIdentification(data?.patientInfo?.citizen_no),
    examDay: examDate[0],
    examMonth: examDate[2],
    examMonthEn: THAI_MONTHS[examDate[2]],
    examYear: examDate[3],
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatQuarantineReport = (data: any) => {
  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatCovidReport = (data: any) => {
  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
  };
};

const formatTravelReport = (data: any) => {
  const formattedSpecialRequire = formatSpecialRequire(data?.specialRequire);

  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
    requireFirstLine: formattedSpecialRequire[0],
    requireSecondLine: formattedSpecialRequire[1],
    requireThirdLine: formattedSpecialRequire[2],
  };
};

const formatVisaExtReport = async (data: any, params: any) => {
  const getNationalityText = params?.nationality
    ? params.nationality.find((item: any) => item.value === data?.patientNationality)?.text
    : " ";

  const [doctorDetailResp] = await DoctorDetail.retrieve({
    apiToken: params.apiToken,
    pk: data?.doctorEncounter,
  });

  const doctorData = doctorDetailResp || {};
  const doctorNameTh = doctorData ? `${doctorData.full_name}` : " ";
  const doctorNameEn = doctorData ? `${doctorData.first_name_en} ${doctorData.last_name_en}` : " ";

  const { nationEn, nationTh } = splitNationality(getNationalityText);

  return {
    ...data,
    formatCreatedDate: formatCreatedDate(data?.createdDate)[0],
    formatCreatedTime: formatCreatedDate(data?.createdDate)[1],
    patientNationalityText: getNationalityText,
    patientNationalityTextEn: nationEn,
    patientNationalityTextTh: nationTh,
    doctorNameFormTh: doctorNameTh,
    doctorNameFormEn: doctorNameEn,
  };
};

const formatDentistReport = async (data: any, params: any) => {
  const {
    patient_name_th,
    patient_name_en,
    patient_age_th,
    patient_age_en,
    doctor_name_th,
    doctor_name_en,
    ...formatted
  } = data;
  const type = data?.reportType;
  const printCount = data?.print_count;
  return {
    ...data,
    type,
    printCount,
  };
};

/* ------------------------------------------------------ */
/*                          Utils                         */
/* ------------------------------------------------------ */
const splitNationality = (text: string) => {
  if (!text) {
    return { nationEn: "", nationTh: "" };
  }

  const parts = text.split(" ");
  const nationEn = parts[0].trim();
  const nationTh = parts[parts.length - 1].trim();

  return { nationEn, nationTh };
};

const formatSpecialRequire = (require: string[] | null) => {
  if (!require) {
    return [" ", " ", " "];
  }

  const result = [];
  let currentLine = "";

  for (let i = 0; i < require.length; i++) {
    if (currentLine === "") {
      currentLine = require[i];
    } else {
      currentLine += `, ${require[i]}`;
    }

    if ((i + 1) % 4 === 0 || i === require.length - 1) {
      result.push(currentLine);
      currentLine = "";
    }
  }

  while (result.length < 3) {
    result.push(" ");
  }

  return result;
};

const visitTypeCheck = (data: any) => {
  // VisitType
  const patientVisitType = data?.encounterInfo?.type;

  // [0] = "visitType Text", [1] = "visitType Start Date", [2] = "visitType End Date", [3] = "day Durations", [4] = "Exam Date"
  switch (patientVisitType) {
    case "OPD":
      return [
        ["ผู้ป่วยนอก", "Outpatient"],
        data?.opdStartDate,
        data?.opdEndDate,
        dayDurationCal(data?.opdStartDate, data?.opdEndDate),
        [data?.examDate, data?.examDate],
      ];
    case "IPD":
      return [
        ["ผู้ป่วยใน", "Inpatient"],
        data?.ipdStartDate,
        data?.ipdEndDate,
        dayDurationCal(data?.ipdStartDate, data?.ipdEndDate),
        [
          `${data?.admitStartDate} ถึง ${data?.admitEndDate}`,
          `${data?.admitStartDate} to ${data?.admitEndDate}`,
        ],
      ];
    default:
      return [["", ""], "", "", 0, ["", ""]];
  }
};

// Count Diseases
const countDisease = (data: any) => {
  const diseasesArray = [
    data?.isFirstDisease,
    data?.isSecondDisease,
    data?.isThirdDisease,
    data?.isFourthDisease,
    data?.isFifthDisease,
  ];
  return Array.from(
    { length: diseasesArray.filter(Boolean).length },
    (_, index) => `${index + 1}.`
  );
};

// Format Created Date
const formatCreatedDate = (date: any) => {
  // [0] = Date, [1] = Time
  return [date.slice(0, 10), date.slice(12, 17)];
};

// Calculate Day Duration between Start Absence to End Absence
const dayDurationCal = (inputStartDate: string, inputEndDate?: string): number | string => {
  if (!(inputStartDate && inputEndDate)) {
    return "";
  }

  const startDate = moment(beToAd(inputStartDate));
  const endDate = moment(beToAd(inputEndDate));

  // Format Date must be "YYYY-MM-DD" before
  return moment.duration(moment(endDate).diff(moment(startDate))).asDays() + 1; // include start date
};

const filterObject = async (object: any, fieldsRemove: any[]) => {
  if (!object) return;

  return Object.fromEntries(Object.entries(object).filter(([key]) => !fieldsRemove.includes(key)));
};

const mapDoctorOptions = (list: any[]) => {
  return (list || []).filter(Boolean).map((item: any) => ({
    key: item.id,
    value: item.id,
    text: item.name_code || item.full_name,
    name_th: item.full_name,
    name_en: `${item.first_name_en} ${item.last_name_en}`,
    certificate_no: item?.certificate_no,
  }));
};

const formatCuMedCertCreatedDate = (utcDate: string, formatType: "AD" | "BE" = "AD") => {
  if (!utcDate) {
    return null;
  };

  const date: moment.Moment = moment(utcDate);

  if (!date.isValid()) {
    console.error("[Med Cert]: Invalid open encounter date format!");
    return null;
  }

  if (formatType === "BE") {
    const formattedDate = date.format("DD/MM");
    const yearBe = date.year() + 543;
    return `${formattedDate}/${yearBe}`;
  } else if (formatType === "AD") {
    return date.format("DD/MM/YYYY");
  } else {
    console.error(`[Med Cert]: Invalid format type! "AD" for ค.ศ. "BE" for พ.ศ.`);
    return null;
  }
};

// For Form Dentist Certificate Only
export const setPDFMedCertificateData: Handler = (controller: any, data: any) => {
  let result: any = { ...data };

  const createdMomemt: moment.Moment = moment(result.created, "YYYY-MM-DD HH:mm:ss");

  const reportNameEn = reg.exec(result.report_name)?.[1];

  // Log report name
  console.log(reportNameEn, reg.exec(result.report_name));

  if (["TH", "ไทย"].includes(data?.reportLanguage) || ["TH", "ไทย"].includes(data?.language)) {
    result.facultyHeader = "คณะทันตแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย";
    result.createdDate = formatCuMedCertCreatedDate(controller?.created_utc, "BE") || momentToStringBE(createdMomemt);
    result.codeText = "เลขที่ใบรับรองแพทย์";
    result.doctorNameText = "ข้าพเจ้า";
    result.certNoText = "ใบอนุญาตประกอบวิชาชีพทันตกรรมเลขที่";
    result.patientNameText = "ได้ทำการตรวจรักษา";
    result.patientAgeText = "อายุ";
    result.patientHNText = "เลขที่ประจำตัวผู้ป่วย";
    result.citizenNoText = "เลขที่บัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง";
    result.createdDateText = "ตรวจเมื่อวันที่";
    result.diagnosisText = "การวินิจฉัยโรค";
    result.treatmentText = "การรักษาที่ให้";
    result.detailText = "ความเห็นทันตแพทย์";
    result.footer = `วัน/เวลาที่พิมพ์ ${result?.print_datetime}`;
    result.signText = "ลงชื่อ";
    result.pediatricSignText = "นักศึกษาทันตแพทย์";
    result.doctorSignText = "ทันตแพทย์ผู้ให้การตรวจ/รักษา";
    result.report_name = result.report_name || "ใบรับรองแพทย์";
    result.division_name = result.division_name || "";
    // result.code = result.pk || "-";
  } else {
    result.facultyHeader = "Faculty of Dentistry, Chulalongkorn University";
    result.createdDate = formatCuMedCertCreatedDate(controller?.created_utc) || createdMomemt.format("DD/MM/YYYY");
    result.codeText = "Certificate No";
    result.doctorNameText = "Physician's name";
    result.certNoText = "Dental License No.";
    result.patientNameText = "Has examined (Patient's name)";
    result.patientAgeText = "Age";
    result.patientHNText = "Hospital Number";
    result.citizenNoText = "Identification No. / Passport Number";
    result.createdDateText = "Date of Examication";
    result.diagnosisText = "Diagnosis";
    result.treatmentText = "Treatment";
    result.detailText = "Recommendation";
    result.footer = `Printed Date/Time ${result?.print_datetime}`;
    result.signText = "";
    result.pediatricSignText = "Dentist's signature";
    result.doctorSignText = "Dentist Supervisor's signature";
    result.report_name = reportNameEn || (result.report_name === "ใบรับรองแพทย์" ? "Medical Certifcate" : result.report_name) || "Medical Certificate";
    result.division_name = result.division_name || "";
    // result.code = result.pk || "-";
  }

  return result;
};
